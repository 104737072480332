<template>
    <div>
        <div style="width: 75%;height: 100%;float: left;margin-left: 50px;">
            <template>
                <el-table
                        :data="tableData"
                        style="width: 100%"
                        max-height="250">
                    <el-table-column
                            fixed
                            prop="createTime"
                            label="日期"
                            width="160">
                    </el-table-column>
                    <el-table-column
                            prop="bankName"
                            label="所属银行"
                            width="120">
                    </el-table-column>
                    <el-table-column
                            prop="cardId"
                            label="银行卡号"
                            width="220">
                    </el-table-column>
                    <el-table-column
                            prop="orderNo"
                            label="订单号"
                            width="220">
                    </el-table-column>
                    <el-table-column
                            prop="payAmt"
                            label="付款金额"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="payAmt"
                            label="分账金额"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="220">
                        <template slot-scope="scope">
                            <el-button v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 0" @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small" style="color: #FF9800;">
                               支付
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status != 0 &&tableData[scope.$index].status != 4 && tableData[scope.$index].status != 5" @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small" style="color: red;">
                                结算
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 1" type="text" size="small" style="color: #FF9800;">
                                已结算
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 3 && tableData[scope.$index].status == 7 &&tableData[scope.$index].status != 4 && tableData[scope.$index].status != 5" @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small" style="color: #FF9800;">
                                部分已结算
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 1" @click.native.prevent="refund(scope.$index, tableData)" type="text" size="small" style="color: #3d97d4;">
                                退款
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 3 && tableData[scope.$index].status == 1" @click.native.prevent="partRefund(scope.$index, tableData)" type="text" size="small" style="color: #FF9800;">
                                部分退款
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 4" type="text" size="small" style="color: #3d97d4;">
                                退款中
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 5" type="text" size="small" style="color: #3d97d4;">
                                已退款
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 0 && tableData[scope.$index].status == 6" type="text" size="small" style="color: #3d97d4;">
                                退款失败
                            </el-button>
                            <el-button v-if="tableData[scope.$index].isPenny == 3 && tableData[scope.$index].status == 7" type="text" size="small" style="color: #FF9800;">
                                部分已退款
                            </el-button>
                            <el-button  type="text" size="small" style="color: #3d97d4;">
                                详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <div v-if="showPenny == true">
                <div style="margin-left: 20px;margin-bottom: 20px;margin-top: 10px;">
                    <div>奖金详情</div>
                </div>

                <div style="margin-left: 20px;margin-bottom: 20px;margin-top: 10px;">请确认以下人员获得几等奖</div>
                <div style="margin-left: 20px;">
                    <el-checkbox-group v-model="checked" @change="checkedUserList">
                        <!-- label绑定的值是要传的值 -->
                        <el-checkbox :label="item.value" v-for="(item,index) in settleAccountUserList" :key="index">
                            <!-- 视图上显示给用户看的值 -->
                            {{ item.label }}
                            <input style="width: 60px;"   :id="item.value" value="">
                        </el-checkbox>

                    </el-checkbox-group>

                    <div style="margin-top: 20px;">
                        <el-button type="primary" @click="paymentConfirmCreate">确 定</el-button>
                        <el-button @click="showPenny = false">取消</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "adapay_div_members",
        data(){
            return{
                tableData:[],
                showPenny:false,
                paymentId:"",
                orderNo:"",
                payAmt:0,
                //复选框
                settleAccountUserList:[],
                checked:[],
            }
        },
        mounted() {
            this.querySettleAccountUserList();
            this.queryPennyList();
        },
        methods:{
            //获取选中转账的人
            checkedUserList(value){
                this.checked = value;
            },

            //获取延时分账信息列表
            queryPennyList(){
                var that = this;
                var params = {};
                that.newApi.queryPennyList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.tableData = res.data;
                    }
                })
            },

            //获取所有绑定结算账户列表
            querySettleAccountUserList(){
                var that = this;
                var params = {};
                that.newApi.querySettleAccountUserList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.settleAccountUserList = res.data;
                        console.log(that.settleAccountUserList);
                    }
                })
            },

            //点击结算
            deleteRow(index, rows) {
                var that = this;
                console.log(rows[index]);
                that.showPenny = true;
                that.paymentId = rows[index].paymentId;
                that.orderNo = rows[index].orderNo;
                that.payAmt = rows[index].payAmt;
            },
            //分账确定
            paymentConfirmCreate(){
                var that = this;
                if(that.checked.length == 0){
                    that.utils.err("分账人员不能为空");
                    return false;
                }
                let money = 0;
                let userList = [];
                for (let i = 0; i < that.checked.length; i++) {
                    let obj = {};
                    obj.memberId = that.checked[i];
                    obj.amount = that.utils.keepTwoDecimalFull(document.getElementById(that.checked[i]).value);
                    money += parseFloat(obj.amount);
                    userList.push(obj);
                }
                if(money == 0){
                    that.utils.err("金额不能为空");
                    return false;
                }
                if(money == 0){
                    that.utils.err("分账金额不能为0");
                    return false;
                }
                var params = {};
                params.userList = JSON.stringify(userList);
                console.log(money);

                params.payAmt = that.utils.keepTwoDecimalFull(money);
                params.paymentId = that.paymentId
                params.orderNo = that.orderNo;
                that.newApi.paymentConfirmCreate(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.queryPennyList();
                        that.showPenny = false;
                        that.paymentId = "";
                        that.orderNo = "";
                        that.payAmt = 0;
                    }
                })

                console.log(params);
            },

            //分账申请部分退款
            partRefund(index, rows){
                var that = this;
                var params = {};
                params.paymentId = rows[index].paymentId;
                params.reverseAmt = that.utils.keepTwoDecimalFull(rows[index].payAmt - rows[index].settlementAmount);
                that.newApi.paymentReverseIndividual(params).then((res) =>{
                    if(res.isSuccess == 1){
                        that.queryPennyList();
                    }
                })
            },

            //分账申请退款
            refund(index, rows){
                var that = this;
                var params = {};
                params.paymentId = rows[index].paymentId;
                params.reverseAmt = that.utils.keepTwoDecimalFull(rows[index].payAmt);
                that.newApi.paymentReverseIndividual(params).then((res) =>{
                    if(res.isSuccess == 1){
                        that.queryPennyList();
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>